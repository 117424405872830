import { createApp } from 'vue'
import App from './App.vue'
import router from './plugins'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import './assets/site.css'

import * as ElIconModules from '@element-plus/icons-vue'
import VueUeditorWrap from 'vue-ueditor-wrap';

var app = createApp(App);
app.use(router);
app.use(ElementPlus, {
    locale: zhCn,
    // size:'small'
});
app.use(VueUeditorWrap);

Object.keys(ElIconModules).forEach(function (key) {
    app.component(ElIconModules[key].name, ElIconModules[key])
  });
app.mount('#app');


//全局配置ajax
(function () {
  $.ajaxSetup({
    xhrFields: {
      withCredentials: true  //支持附带详细信息
    },
    crossDomain: true,//请求偏向外域
    // contentType: 'application/x-www-form-urlencoded',
    error: res => {
      //请求异常处理登录
      if (res.status == 599) {
        //当前用户没有登录
        alert('您没有登录或登录超时');
        router.replace('login');
      }
    }
  });

  //js 日期格式化
  Date.prototype.format = function (fmt) {
    var o = {
      "M+": this.getMonth() + 1,                 //月份 
      "d+": this.getDate(),                    //日 
      "h+": this.getHours(),                   //小时 
      "m+": this.getMinutes(),                 //分 
      "s+": this.getSeconds(),                 //秒 
      "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
      "S": this.getMilliseconds()             //毫秒 
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
      if (new RegExp("(" + k + ")").test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      }
    }
    return fmt;
  };
  // window.TencentConfig.useDefault();
 //使用默认 对象存储
 setTimeout(() => {
    

  // window.TencentConfig = {
//     Bucket: 'jby-1322434753',  /* 存储桶，必须字段 */
//     Region: 'ap-beijing',    /* 存储桶所在地域，必须字段 */
//     domain: 'xxx/',
//     SecretId: 'xx',
//     SecretKey: 'xx'
// }
  window.TencentConfig = {
    Bucket: 'bj-1303200487',  /* 存储桶，必须字段 */
    Region: 'ap-beijing',    /* 存储桶所在地域，必须字段 */
    domain: 'https://lawz03data.qianlekh.com/',
    SecretId: 'AKIDCF5lLkTB3rVyJW3SxQziP6nSAk9ScicL',
    SecretKey: 'nQ4rX4awAC2jIgvYr5Ocku3FNNCsQsEe'
} 
}, 1000); 

})();

